<template>
  <b-overlay :show="loading">
    <b-row class="mt-5">
      <b-col md="8">
        <div>
          <b-card title="Company Information">
            <b-card-text>
              Update your company details below
            </b-card-text>

            <div class="card-body">
              <validation-observer ref="newUserForm" #default="{invalid}">
                <b-form ref="form" @submit.prevent="onUpdateCompany">
                  <div class="mb-2">
                    <b-media class="text-center">
                      <b-avatar ref="previewEl" badge-variant="transparent" :src="companyLogoPath" :text="avatarText(`${currentUser.first_name} ${currentUser.last_name}`)" :variant="`light-${resolveStatusVariant(getStatusTextForClient(getValueFromSource(currentUser, 'status')))}`" size="10rem">
                        <template #badge>
                          <b-button variant="primary" class="btn-icon rounded-circle" @click="$refs.avatar.$el.childNodes[0].click()">
                            <feather-icon icon="CameraIcon" />
                          </b-button>
                        </template>
                      </b-avatar>
                    </b-media>

                    <div>
                      <b-form-file v-show="false" ref="avatar" accept="image/*" placeholder="" no-drop @input="onImageRenderer" />
                    </div>
                  </div>

                  <div class="mb-1">
                    <validation-provider #default="{ errors }" name="Company Name" vid="company_name" rules="required">
                      <b-form-group label="Company Name" label-for="company_name">
                        <template #label>
                          <span>Company Name<sup style="color: tomato">*</sup></span>
                        </template>
                        
                        <b-form-input
                          id="company_name"
                          v-model="company_name"
                          trim
                          autofocus
                          placeholder="Ex: Workers GH Limited"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>

                  <div class="mb-1">
                    <validation-provider #default="{ errors }" name="TIN" vid="company_name" rules="required">
                      <b-form-group label="TIN" label-for="company_name">
                        <template #label>
                          <span>TIN<sup style="color: tomato">*</sup></span>
                        </template>
                        
                        <b-form-input
                          id="company_tin"
                          v-model="company_tin"
                          trim
                          autofocus
                          placeholder=""
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>

                  <div class="mb-1">
                    <validation-provider #default="{ errors }" name="Company Email" vid="company_email" rules="email">
                      <b-form-group label="Company Email" label-for="company_email">
                        <b-form-input
                          id="company_email"
                          v-model="company_email"
                          trim
                          autofocus
                          placeholder="Ex: info@workersgh.com"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>

                  <div class="mb-1">
                    <validation-provider #default="{ errors }" name="Company Contact" vid="company_contact" rules="">
                      <b-form-group label="Company Contact" label-for="company_contact">
                        <b-form-input
                          id="company_contact"
                          v-model="company_contact"
                          trim
                          autofocus
                          placeholder="Ex: 0309052213"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>

                  <div class="mb-1">
                    <validation-provider #default="{ errors }" name="Company Description" vid="company_description" rules="">
                      <b-form-group label="Company Description" label-for="company_description">
                        <b-form-textarea
                          id="company_description"
                          v-model="company_description"
                          rows="10"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>

                  <div class="mb-1">
                    <validation-provider #default="{ errors }" name="Company Address" vid="company_address" rules="">
                      <b-form-group label="Company Address" label-for="company_address">
                        <b-form-input
                          id="company_address"
                          v-model="company_address"
                          trim
                          autofocus
                          placeholder="Ex: ABC Street, Cantonments"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>

                  <div class="mb-1">
                    <b-form-group label="Country" label-for="country">
                      <template #label>
                        <span>Country<span style="color: tomato">*</span></span>
                      </template>

                      <validation-provider #default="{ errors }" name="country" rules="required">
                        <v-select
                          id="country"
                          v-model="country"
                          :loading="loading"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="countryOptions"
                          :reduce="val => val.value"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="mb-1">
                    <b-form-group label="Region/State/Province" label-for="region">
                      <template #label>
                        <span>Region/State/Province<span style="color: tomato">*</span></span>
                      </template>

                      <validation-provider #default="{ errors }" name="region" rules="required">
                        <v-select
                          id="region"
                          v-model="region"
                          :loading="loading"             
                          :clearable="false"
                          :options="regionOptions"
                          :reduce="val => val.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="mb-1">
                    <b-form-group label="City" label-for="city">
                      <template #label>
                        <span>City<span style="color: tomato">*</span></span>
                      </template>

                      <validation-provider #default="{ errors }" name="city" rules="required">
                        <v-select
                          id="city"
                          v-model="city"
                          :loading="loading"             
                          :clearable="false"
                          :options="cityOptions"
                          :reduce="val => val.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="mb-1">
                    <b-form-group label="Town/Area" label-for="town">
                      <validation-provider #default="{ errors }" name="town" rules="">
                        <v-select
                          id="town"
                          v-model="town"
                          :loading="loading"             
                          :clearable="false"
                          :options="townOptions"
                          :reduce="val => val.value"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div>
                    <b-button class="py-1 w-25" type="submit" variant="primary" block :disabled="invalid">
                      Update
                    </b-button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { get } from "lodash";
import { required, email } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import vSelect from 'vue-select'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BCard,
  BMedia,
  BForm,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BOverlay,
  BFormFile,
  BCardText,
  BCardTitle,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";

export default {
  name: "Update",
  components: {
    vSelect,

    BCard,
    BForm,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    BButton,
    BOverlay,
    BCardText,
    BFormFile,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      loading: false,
      companyLogoPath: '',
      providerData: null,

      company_name: '',
      company_tin: '',
      company_email: '',
      company_contact: '',
      company_address: '',
      company_description: '',

      country: 2300660,
      region: '',
      city: '',
      town: '',

      countryOptions: [],
      regionOptions: [],
      cityOptions: [],
      townOptions: [],

      // validations 
      required,
      email
    }
  },
  watch: {
    currentUser: {
      handler(){
        if (!this.loaded){
          this.country = this.getValueFromSource(this.currentUser, 'country', 2300660);
          this.region = this.getValueFromSource(this.currentUser, 'region');
          this.city = this.getValueFromSource(this.currentUser, 'city');
          this.town = this.getValueFromSource(this.currentUser, 'town');
        }
      },
      deep: true,
      immediate: true
    },
    country: {
      handler(changed, old){
        if (changed && changed !== old){
          this.fetchGeoData(changed, this.onRegionsFetch);
        }
      },
      immediate: true
    },
    region: {
      handler(changed, old){
        if (changed && old !== changed){
          this.fetchGeoData(changed, this.onCitiesFetch);
        }
      },
      immediate: true
    },
    city: {
      handler(changed, old){
        console.log({ changed, old })
        if (changed && old !== changed){
          this.fetchGeoData(changed, this.onTownsFetch);
        }
      },
      immediate: true
    },
    providerData: {
      handler() {
        this.company_tin = this.getValueFromSource(this.providerData, 'tin', '');
        this.companyLogoPath = this.getValueFromSource(this.providerData, 'logo.path', '');
        this.company_name = this.getValueFromSource(this.providerData, 'company_name', '');
        this.company_email = this.getValueFromSource(this.providerData, 'company_email', '');
        this.company_contact = this.getValueFromSource(this.providerData, 'company_contact', '');
        this.company_address = this.getValueFromSource(this.providerData, 'company_address', '');
        this.company_description = this.getValueFromSource(this.providerData, 'company_description', '');
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.fetchProvider()
    this.fetchCountries();
  },
  methods: {
    async fetchProvider() {
      try {
        this.loading = true;

        const request = await this.useJwt().providerService.fetchProvider();
        const { data } = request.data;
        this.providerData = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchCountries() {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchCountries({ criteria: 'all' });
        const { data } = request.data;

        this.countryOptions = data.map(country => ({
          label: country.country,
          value: country.geo_name_id
        }));

        if (this.loaded){
          this.regionOptions = [];
          this.cityOptions = [];
          this.townOptions = [];

          this.region = '';
          this.city = '';
          this.town = '';
        } else if (!this.loaded){
          this.step += 1;
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async fetchGeoData(geo_id, cb) {
      try {
        this.loading = true;

        const request = await this.useJwt().sharedService.fetchGeoData({ geo_id });
        const { data } = request.data;
        cb(data)
        if (!this.loaded){
          this.step += 1;
        }
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onUpdateCompany() {
      try {
        this.loading = true;

        const formData = new FormData();
        formData.append('tin', this.company_tin);
        formData.append('company_name', this.company_name);
        formData.append('company_email', this.company_email);
        formData.append('company_contact', this.company_contact);
        formData.append('company_address', this.company_address);
        formData.append('company_description', this.company_description);

        formData.append('country', this.country);
        formData.append('region', this.region);
        formData.append('city', this.city);
        formData.append('town', this.town);

        if (this.companyLogo) {
          formData.append("company_logo", this.companyLogo);
        }

        await this.useJwt().providerService.updateProvider(formData);

        if (this.companyLogo) {
          this.companyLogo = null;
        }

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Company information updated successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    },
    onRegionsFetch(data) {
      this.cityOptions = [];
      this.townOptions = [];

      this.regionOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));
      if (this.loaded){
        this.region = '';
        this.city = '';
        this.town = '';
      }
    },
    onCitiesFetch(data) {
      this.townOptions = [];

      this.cityOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));

      if (this.loaded){
        this.city = '';
        this.town = '';
      }
    },
    onTownsFetch(data) {
      this.townOptions = data.map(geo_data => ({
        label: geo_data.name,
        value: geo_data.geonameId
      }));

       if (this.loaded){
        this.town = '';
      }
    },
    onImageRenderer(file) {
      const reader = new FileReader()

      reader.addEventListener(
        'load',
        () => {
          this.companyLogo = file;
          this.companyLogoPath = reader.result
        },
        false,
      )

      if (file) {
        reader.readAsDataURL(file)
      }
    },
  }
}
</script>


<style lang="scss">
$neutral : #80A0AF;
$main-color: #144F6A;

.exxtra-input {
  position: relative;
  display: block;
  color:  #80A0AF !important;

  .leading{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    padding-left: 12px;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
  }

  .trailing {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 12px;
    padding-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .otp {
    letter-spacing: 2rem;
    text-align: center;
    font-weight: bold;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    padding-left: 3.5rem !important;
  }


  input {
    border: 1px solid $neutral;
    border-radius: 5px;
    width: 100%;
    top: 50%;
    bottom: 50%;
    min-width: 50px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.75rem;
    color:  #80A0AF !important;

    &:focus {
      outline: none;
      border: 1px solid $main-color;
    }

    &::placeholder {
      color: $neutral;
      opacity: 0.3;
    }
    &:invalid {
      border-color: red;
    }
    &:required {
      border-color: red;
    }
    &:-ms-input-placeholder {
      color: $neutral;
    }

  }
}

</style>

