var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',{staticClass:"mt-5"},[_c('b-col',{attrs:{"md":"8"}},[_c('div',[_c('b-card',{attrs:{"title":"Company Information"}},[_c('b-card-text',[_vm._v(" Update your company details below ")]),_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"newUserForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onUpdateCompany($event)}}},[_c('div',{staticClass:"mb-2"},[_c('b-media',{staticClass:"text-center"},[_c('b-avatar',{ref:"previewEl",attrs:{"badge-variant":"transparent","src":_vm.companyLogoPath,"text":_vm.avatarText(((_vm.currentUser.first_name) + " " + (_vm.currentUser.last_name))),"variant":("light-" + (_vm.resolveStatusVariant(_vm.getStatusTextForClient(_vm.getValueFromSource(_vm.currentUser, 'status'))))),"size":"10rem"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.avatar.$el.childNodes[0].click()}}},[_c('feather-icon',{attrs:{"icon":"CameraIcon"}})],1)]},proxy:true}],null,true)})],1),_c('div',[_c('b-form-file',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"avatar",attrs:{"accept":"image/*","placeholder":"","no-drop":""},on:{"input":_vm.onImageRenderer}})],1)],1),_c('div',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"Company Name","vid":"company_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Name","label-for":"company_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Company Name"),_c('sup',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"company_name","trim":"","autofocus":"","placeholder":"Ex: Workers GH Limited","state":errors.length > 0 ? false : null},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"TIN","vid":"company_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"TIN","label-for":"company_name"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("TIN"),_c('sup',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"company_tin","trim":"","autofocus":"","placeholder":"","state":errors.length > 0 ? false : null},model:{value:(_vm.company_tin),callback:function ($$v) {_vm.company_tin=$$v},expression:"company_tin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"Company Email","vid":"company_email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Email","label-for":"company_email"}},[_c('b-form-input',{attrs:{"id":"company_email","trim":"","autofocus":"","placeholder":"Ex: info@workersgh.com","state":errors.length > 0 ? false : null},model:{value:(_vm.company_email),callback:function ($$v) {_vm.company_email=$$v},expression:"company_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"Company Contact","vid":"company_contact","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Contact","label-for":"company_contact"}},[_c('b-form-input',{attrs:{"id":"company_contact","trim":"","autofocus":"","placeholder":"Ex: 0309052213","state":errors.length > 0 ? false : null},model:{value:(_vm.company_contact),callback:function ($$v) {_vm.company_contact=$$v},expression:"company_contact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"Company Description","vid":"company_description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Description","label-for":"company_description"}},[_c('b-form-textarea',{attrs:{"id":"company_description","rows":"10","state":errors.length > 0 ? false : null},model:{value:(_vm.company_description),callback:function ($$v) {_vm.company_description=$$v},expression:"company_description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"Company Address","vid":"company_address","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Company Address","label-for":"company_address"}},[_c('b-form-input',{attrs:{"id":"company_address","trim":"","autofocus":"","placeholder":"Ex: ABC Street, Cantonments","state":errors.length > 0 ? false : null},model:{value:(_vm.company_address),callback:function ($$v) {_vm.company_address=$$v},expression:"company_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Country","label-for":"country"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Country"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"country","loading":_vm.loading,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryOptions,"reduce":function (val) { return val.value; },"clearable":false},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Region/State/Province","label-for":"region"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Region/State/Province"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"region","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"region","loading":_vm.loading,"clearable":false,"options":_vm.regionOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"City","label-for":"city"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("City"),_c('span',{staticStyle:{"color":"tomato"}},[_vm._v("*")])])]},proxy:true}],null,true)},[_c('validation-provider',{attrs:{"name":"city","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"city","loading":_vm.loading,"clearable":false,"options":_vm.cityOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{attrs:{"label":"Town/Area","label-for":"town"}},[_c('validation-provider',{attrs:{"name":"town","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"town","loading":_vm.loading,"clearable":false,"options":_vm.townOptions,"reduce":function (val) { return val.value; },"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.town),callback:function ($$v) {_vm.town=$$v},expression:"town"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',[_c('b-button',{staticClass:"py-1 w-25",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid}},[_vm._v(" Update ")])],1)])]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }